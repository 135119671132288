<template>
  <highcharts class="wrapper-factor-chart" :options="options" />
</template>

<script>
import { mean } from "simple-statistics";
import { computed } from "@vue/runtime-core";

export default {
  props: {
    industryMaturityScore: {
      type: Array,
      default: [],
    },
    factorsDetail: {
      type: Array,
      default: [],
    },
    industryName: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const data = computed(() => props.industryMaturityScore);
    const currentUserFactors = computed(() => {
      const [
        leader,
        team,
        process,
        tools,
        data,
        ecosystem,
      ] = props.factorsDetail;
      const result = {
        leader: leader.totalScore,
        data: data.totalScore,
        ecosystem: ecosystem.totalScore,
        team: team.totalScore,
        tools: tools.totalScore,
        process: process.totalScore,
      };
      return result;
    });
    const dataFactor = data.value.map((d) => d.avgFactor);
    const avgData = mean(dataFactor.map((d) => d.data));
    const avgEcosystem = mean(dataFactor.map((d) => d.ecosystem));
    const avgLeader = mean(dataFactor.map((d) => d.leader));
    const avgProcess = mean(dataFactor.map((d) => d.process));
    const avgTeam = mean(dataFactor.map((d) => d.team));
    const avgTools = mean(dataFactor.map((d) => d.tools));
    const createElemHtml = (currentScore, text, className) => {
      return `<div class="wrapper-categories ${className}">
        <div class="factor-label">${text}</div>
        <div class="score-wrapper">${currentScore}/15</div>
      </div>`;
    };
    const options = {
      credits: {
        enabled: false,
      },
      chart: {
        polar: true,
        type: "line",
        style: {
          fontFamily: "Gotham, sans-serif",
        },
      },

      title: {
        text: `Your Industry: ${props.industryName} `,
        style: {
          fontWeight: "bolder",
          color: '#3526bf'
        },
      },

      pane: {
        size: "80%",
        startAngle: 90,
      },

      xAxis: {
        categories: [
          "Data",
          "Ecosystem",
          "Organizational Process",
          "Team Capability",
          "Leadership",
          "Tools",
        ],
        tickmarkPlacement: "on",
        lineWidth: 0,
        labels: {
          useHTML: true,
          style: {
            fontSize: "10px",
          },
          formatter: function() {
            const mapValueObject = {
              Data: currentUserFactors.value.data,
              Ecosystem: currentUserFactors.value.ecosystem,
              "Organizational Process": currentUserFactors.value.process,
              "Team Capability": currentUserFactors.value.team,
              Leadership: currentUserFactors.value.leader,
              Tools: currentUserFactors.value.tools,
            };
            const mapClassNameObject = {
              Data: "data",
              Ecosystem: "ecosystem",
              "Organizational Process": "process",
              "Team Capability": "team",
              Leadership: "leader",
              Tools: "tools",
            };
            if (mapValueObject[this.value]) {
              const currentScore = mapValueObject[this.value];
              const className = mapClassNameObject[this.value];
              return createElemHtml(currentScore, this.value, className);
            }
            return false;
          },
        },
      },

      yAxis: {
        gridLineInterpolation: "polygon",
        lineWidth: 0,
        min: 0,
        max: 15,
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },

      tooltip: {
        shared: true,
        pointFormat:
          '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
        outside: true,
      },

      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "vertical",
        itemMarginTop: 8,
      },

      series: [
        {
          name: "Your Organization Score Based on Your Assessment",
          data: [
            currentUserFactors.value.data,
            currentUserFactors.value.ecosystem,
            currentUserFactors.value.process,
            currentUserFactors.value.team,
            currentUserFactors.value.leader,
            currentUserFactors.value.tools,
          ],
          pointPlacement: "on",
          color: "#cc0000",
        },
        {
          name: "Industry Average Score",
          data: [
            avgData,
            avgEcosystem,
            avgProcess,
            avgTeam,
            avgLeader,
            avgTools,
          ],
          pointPlacement: "on",
          color: "#595959",
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              pane: {
                size: "70%",
              },
            },
          },
        ],
      },
    };
    return {
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';

.wrapper-factor-chart {
  &::v-deep {
    .wrapper-categories {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .factor-label {
        text-align: center;
        margin-bottom: 4px;
      }

      .score-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 30px;
        border-radius: 50px;
        border: 4px solid black;
        font-weight: bold;
      }

      &.data {
        .factor-label {
          color: map.get(variables.$colors, "green", "500");
        }

        .score-wrapper {
          border-color: map.get(variables.$colors, "green", "500");
          color: map.get(variables.$colors, "green", "500");
          background-color: map.get(variables.$colors, "green", "300");
        }
      }

      &.ecosystem {
        .factor-label {
          color: map.get(variables.$colors, "amber", "500");
        }

        .score-wrapper {
          border-color: map.get(variables.$colors, "amber", "500");
          color: map.get(variables.$colors, "amber", "500");
          background-color: map.get(variables.$colors, "amber", "300");
        }
      }

      &.process {
        .factor-label {
          color: map.get(variables.$colors, "indigo", "500");
        }

        .score-wrapper {
          border-color: map.get(variables.$colors, "indigo", "500");
          color: map.get(variables.$colors, "indigo", "500");
          background-color: map.get(variables.$colors, "indigo", "300");
        }
      }

      &.team {
        .factor-label {
          color: map.get(variables.$colors, "purple", "500");
        }

        .score-wrapper {
          border-color: map.get(variables.$colors, "purple", "500");
          color: map.get(variables.$colors, "purple", "500");
          background-color: map.get(variables.$colors, "purple", "300");
        }
      }
      &.leader {
        .factor-label {
          color: map.get(variables.$colors, "orange", "500");
        }

        .score-wrapper {
          border-color: map.get(variables.$colors, "orange", "500");
          color: map.get(variables.$colors, "orange", "500");
          background-color: map.get(variables.$colors, "orange", "300");
        }
      }

      &.tools {
        .factor-label {
          color: map.get(variables.$colors, "blue", "500");
        }

        .score-wrapper {
          border-color: map.get(variables.$colors, "blue", "500");
          color: map.get(variables.$colors, "blue", "500");
          background-color: map.get(variables.$colors, "blue", "300");
        }
      }
    }
  }
}
</style>
